import sandaImage from '../images/icons/boxing_gloves.svg'

export const cvData = [
  {
    title: 'EXPERIENCE',
    icon: 'fas fa-briefcase',
    type: 'list',
    items: [
      {
        title: 'CHIEF TECHNICAL OFFICER (CTO)',
        place: 'Smart-Waves',
        date: '(2019 - 2020)',
        description:
          'While working at Smart-Waves, I was responsible for Project development and managing the development team.',
      },
      {
        title: 'Web development trainer',
        place: 'Innova Academy, Talent Lab',
        date: '(2019 - Today)',
        description: 'I taught HTML5, CSS3, JavaScript, PHP, ReactJS and Laravel',
      },
      {
        title: 'Python trainer',
        place: 'SoftWay',
        date: '(2019 - Ajourd’hui)',
        description: 'I taught Python, and Django',
      },
      {
        title: 'Computer Science Professor',
        place: 'iTeam University',
        date: '(2019 - Ajourd’hui)',
        description: 'I taught Python 1, Python2 and algorithmic complexity for Big Data master',
      },
      {
        title: 'Director and Coordinator of Scientific Camp',
        place: 'Association Jeunes Science de Tunisie ',
        date: '(2019)',
        description:
          'During my direction, I was responsible for the smooth running of the scientific camp organized by the Association Jeunes Sciences De Tunisie',
      },
      {
        title: 'Web Developper',
        date: '(2011 - Ajourd’hui)',
      },
      {
        title: 'Computer science trainer',
        place: 'Association Jeunes Sciences de Tunisie',
        date: '(2011 - 2019)',
        description: 'I taught HTML5, CSS3, PHP and Python',
      },
      {
        title: 'Trainer',
        place: 'Xlim Université de Limoges',
        date: '(Mars 2017 - Sép 2017)',
        description:
          'During my internship at Xlim I studied and performed attacks on RSA and AES crypto-systems based on an side-channel attack.',
      },
    ],
  },
  {
    title: 'Education',
    icon: 'fas fa-graduation-cap',
    type: 'list',
    items: [
      {
        title: 'Master MCCA (Mathematics, Cryptology, Coding and Applications)',
        place: 'Limoges University',
        date: '(2015 - 2017)',
        description: "During my Master degree I've studied :",
        items: [
          'Introduction to cryptology',
          'Algorithmics of finite fields',
          'Complexity and computability',
          'Algebra',
          'Programming and algorithmics',
          'Networks and computer systems',
          'Security of Computer and communication technologies',
          'Computer algebra',
          'Arithmetic and number theory for cryptography',
          'Polynomial Systems',
          'Error correcting codes',
          'Statistical tools',
          'Secret key cryptography',
          'Public key cryptography',
          'Cryptographic mechanisms and applications',
          'Development of cryptographic software',
          'Smart Cards and Java Card Development',
          'Certification and secure development',
          'Methodology for safety',
          'Security and implementations on smart cards',
        ],
      },
      {
        title: 'Fundamental License in Mathematics and Application',
        place: 'ESSTT Université de Manar',
        date: '(2011 - 2015)',
        description: "During my License I've studied :",
        items: [
          'Basic mathematics',
          'Physics 1',
          'Optical',
          'Algorithmics and data structure I',
          'Linear algebra',
          'Electromagnetism',
          'Mechanical 2',
          'Analysis',
          'Algorithmics and data structure II',
          'Introduction to cryptography',
          'Differential calculus in Rn',
          'Series and integrals (I)',
          'Algebra and arithmetic',
          'Series and integrale (II)',
          'Euclidean algebra',
          'Probability and statistics (I)',
          'Integral calculation',
          'Differential calculation',
          'Topology',
          'General algebra',
          'Differential equations',
          'Probability',
          'Numerical analysis',
          'Optimization',
          'public key cryptography',
          'secret key cryptography',
        ],
      },
    ],
  },
  {
    title: 'Skills',
    icon: 'fas fa-desktop',
    type: 'stars',
    items: [
      { title: 'HTML5 / CSS3', value: 4.5 },
      { title: 'JavaScript', value: 4 },
      { title: 'PHP', value: 4 },
      { title: 'Python', value: 3.5 },
      { title: 'ReactJS', value: 3.5 },
      { title: 'Laravel', value: 3 },
      { title: 'Node JS', value: 3 },
    ],
  },
  {
    title: 'Languages',
    icon: 'fas fa-language',
    type: 'grid',
    items: [
      { title: 'Arabe', value: 'Maternel' },
      { title: 'Frensh', value: 'bilingue' },
      { title: 'English', value: 'professionel' },
    ],
  },
  {
    title: 'Hobies',
    icon: 'fas fa-heart',
    type: 'icons',
    items: [
      { icon: 'fas fa-volleyball-ball', type: 'fa', text: 'Hand Ball' },
      { icon: 'fas fa-chess-knight', type: 'fa', text: 'Chess' },
      { icon: 'fas fa-gamepad', type: 'fa', text: 'Video games' },
      { icon: 'fas fa-laptop-code', type: 'fa', text: 'Programming' },
      {
        icon: sandaImage,
        type: 'svg',
        text: 'Sanda',
      },
      { icon: 'fas fa-plane-departure', type: 'fa', text: 'Travel' },
    ],
  },
]
