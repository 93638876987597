import React from 'react'
import Wave from './Wave'

const CallToAction = ({ color, icon, text, link }) => {
  if (link) {
    return (
      <a className={`callToAction cTa-${color}`} href={link} target='_blank' rel='noopener noreferrer'>
        {icon && <i className={icon} />} {text}
      </a>
    )
  }
  return null
}

function Nav({ bg1, bg2, color, waveBg1, waveBg2, title, callToAction }) {
  const styleNav = {
    background: `linear-gradient(90deg, ${bg1} 0%, ${bg2} 100%)`,
    color,
  }
  const navColors = { fg: waveBg1, bg: waveBg2 }

  return (
    <nav className='title' style={styleNav}>
      <div className='container py-5'>
        <h1>{title}</h1>
        {callToAction && <CallToAction {...callToAction} />}
      </div>
      <Wave {...navColors} />
    </nav>
  )
}

export default Nav
