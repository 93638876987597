import React from 'react'
import Nav from '../component/Nav'
import Wave from '../component/Wave'
import Page from '../Layout/Page'
import { cvData } from '../data/cv'
import * as style from '../css/cv.module.scss'
import { graphql } from 'gatsby'

const ListItem = ({ title, place, date, description, items }) => {
  const Items = () => {
    if (!items) return <></>
    return (
      <ul>
        {items.map((e, k) => (
          <li key={k}>{e}</li>
        ))}
      </ul>
    )
  }
  return (
    <div className={style.cvListItem + ' my-2'}>
      <h3>{title}</h3>
      <h4>
        {place} <em>{date}</em>
      </h4>
      <p>{description}</p>
      <Items />
    </div>
  )
}

const ListIcons = ({ icon, type, text }) => {
  const iconDom = type === 'fa' ? <i className={icon} /> : <img alt={text} src={icon} />

  return (
    <div className={style.cvIconItem + ' my-2'}>
      <div>{iconDom}</div>
      <h4>{text}</h4>
    </div>
  )
}

const ListGrid = ({ title, value }) => {
  return (
    <div className={'my-2 ' + style.cvStarItem}>
      <h4>{title}</h4>
      <h4>{value}</h4>
    </div>
  )
}

const StarItem = ({ title, value, max = 5 }) => {
  const ShowStars = ({ value }) => {
    let stars = []
    for (var i = 0; i < Math.floor(value); i++) {
      stars.push('fas fa-star')
    }

    let test = value - i
    if (test > 0) {
      stars.push('fas fa-star-half-alt')
    }

    while (stars.length < max) {
      stars.push('far fa-star')
    }

    return (
      <h4>
        {stars.map((e, k) => (
          <i className={`${e} mx-1`} key={k} />
        ))}
      </h4>
    )
  }

  return (
    <div className={style.cvStarItem + ' my-2'}>
      <h4>{title}</h4> <ShowStars value={value} max={max} />
    </div>
  )
}

const ShowData = ({ items, type, max = 5 }) => {
  if (type === 'stars') {
    return (
      <div>
        {items.map((e, k) => (
          <StarItem key={k} {...e} max={max} />
        ))}
      </div>
    )
  } else if (type === 'list') {
    return (
      <>
        {items.map((e, k) => (
          <ListItem key={k} {...e} />
        ))}
      </>
    )
  } else if (type === 'grid') {
    return (
      <div>
        {items.map((e, k) => (
          <ListGrid key={k} {...e} />
        ))}
      </div>
    )
  } else if (type === 'icons') {
    return (
      <div className={style.cvIcons}>
        {items.map((e, k) => (
          <ListIcons key={k} {...e} />
        ))}
      </div>
    )
  }

  return null
}

function Section({ icon, items, title, type }) {
  return (
    <div className={style.cvCat + ' py-2'}>
      <h2>
        <i className={icon} /> {title}
      </h2>
      <ShowData items={items} type={type} />
    </div>
  )
}

function PageCV({
  data: {
    file: { publicURL },
  },
}) {
  return (
    <Page selected='CV' title='Curriculum vitæ' description="Ahmed meftah's Curriculum vitæ">
      <div className={style.cv}>
        <Nav
          title='Curriculum vitæ'
          bg1='#3e0c2f'
          bg2='#6d61fb'
          waveBg1='#ffffff'
          waveBg2='#ffffff'
          callToAction={{ color: 'blue', icon: 'fas fa-file-pdf', text: 'Download PDF', link: publicURL }}
        ></Nav>
        <div className={'container py-5 ' + style.cvContainer}>
          {cvData.map((e, k) => (
            <Section key={k} {...e} />
          ))}
        </div>
        <Wave fg='#1a1a1a' bg='#363636' />
      </div>
    </Page>
  )
}

export default PageCV

export const querry = graphql`
  query MyQuery {
    file(extension: { eq: "pdf" }, name: { eq: "cv_ahmed_meftah" }) {
      extension
      name
      publicURL
    }
  }
`
