import React from 'react'
import { getRandomColor } from '../helpers/color'
import styled from 'styled-components'

interface WaveProps {
  dontshow?: boolean
  fg?: string
  bg?: string
}

const WaveSVG = styled.svg`
  width: 100% !important;
  max-width: 100% !important;
  display: block;
`

function Wave({ fg, bg, dontshow }: WaveProps): React.ReactElement | null {
  let id = Math.floor(Math.random() * 1000)
  let colorfg = fg ? fg : getRandomColor()
  let colorbg = bg ? bg : getRandomColor()
  if (dontshow) return null
  return (
    <WaveSVG xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 90'>
      <defs>
        <linearGradient id={`header-${id}`} x2='1' y2='0'>
          <stop offset='0%' stopColor={colorfg} />
          <stop offset='100%' stopColor={colorbg} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#header-${id})`}
        fillOpacity='1'
        d='m 479,80 c 90,0 215,-37 362,-38 136,-1 296,34 350,34 123,0 203,-92 299,-49 C 1492,27 1444,96 1445,107 808,112 448,112 -3,112 -4,70 -40,22 0,18 193,-3 293,80 479,80 Z'
      ></path>
    </WaveSVG>
  )
}

export default Wave
